import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/ApolloNextAppProvider.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/hooks.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@mui/material/Badge/index.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@mui/material/ButtonGroup/index.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@mui/material/Chip/index.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@mui/material/Divider/index.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@mui/material/Grid/index.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@mui/material/Paper/index.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@mui/material/Skeleton/index.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@mui/material/Stack/index.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@mui/material/SwipeableDrawer/index.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/@mui/material/Typography/index.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/components/layouts/Footer.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/components/layouts/Topbar.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/components/ui/display/Lazy.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/components/ui/feedback/Alert.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/components/ui/feedback/Dialog.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/components/ui/inputs/Button.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/components/ui/inputs/Field.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/components/ui/inputs/FileInput.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/components/ui/inputs/Form.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/components/ui/inputs/IconButton.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/components/ui/layout/Modal.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/contexts/provider.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/contexts/theme.js");
import(/* webpackMode: "eager" */ "/home/elmati/dev/bup/BUP-website/src/styles/globals.scss")