"use client";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    primary: {
      dark: "#003180",
      light: "#336bc6",
      main: "#0047b8",
      contrastText: "#ffffff",
    },
    secondary: {
      dark: "#0e4874",
      light: "#4386b8",
      main: "#1568a7",
      contrastText: "#ffffff",
    },
    tertiary: {
      dark: "#0074a0",
      light: "#33b8ea",
      main: "#00a7e5",
      contrastText: "#000000",
    },
    premium: {
      dark: "#987316",
      light: "#e1b74c",
      main: "#daa520",
      contrastText: "#000000",
    },
    success: {
      dark: "#0b6258",
      light: "#40a397",
      main: "#118c7e",
      contrastText: "#ffffff",
    },
  },
  status: {
    active: "#0047b8",
    danger: "#d32f2f",
    disabled: "#9e9e9e",
    focus: "#ff5722",
    hover: "#e0e0e0",
    info: "#2196f3",
    pending: "#ffc107",
    success: "#118c7e",
    warning: "#ff9800",
  },
});

function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default Theme;
