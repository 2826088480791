"use client";

import { Box, IconButton, Image, Logo } from "components";
import { scrollTo, openUrl } from "utils";

function Footer({ company, documents, links }) {
  return (
    <Box className="BupBrowser-footer" component="footer">
      <Box className="BupFooter-support">
        <Image
          alt="logo-corfo"
          className="BupLogo-large"
          src="logos/corfo.webp"
          height={75}
          width={150}
        />
        <Image
          alt="logo-startup-chile"
          src="logos/startup.webp"
          height={75}
          width={150}
        />
        <Image
          alt="logo-desafio-emprendedor"
          className="BupLogo-large"
          src="logos/desafio.webp"
          height={75}
          width={150}
        />
        <Image
          alt="logo-udd-ventures"
          className="BupLogo-small"
          src="logos/udd.png"
          height={75}
          width={150}
        />
      </Box>
      <Box className="BupFooter-social">
        {socialLinks?.map(([icon, name], i) => (
          <IconButton
            key={i}
            id={"RS-" + name}
            name={name + "-rs"}
            onClick={() => openUrl(links[name])}
            icon={icon}
            iconVariant="fb"
          />
        ))}
      </Box>
      <Box className="BupFooter-policies" component="nav">
        <Box id="BF-logo" onClick={scrollTo}>
          <Logo />
          <span className="BupLogo-span">{company?.alias}</span>
        </Box>
        <Box component="ul">
          <Box component="li">
            &copy; {new Date().getFullYear()} {company?.name}
          </Box>
          <Box
            id="BF-community"
            component="li"
            onClick={() => openUrl(documents?.standards)}
          >
            Normas comunitarias
          </Box>
          <Box
            id="BF-privacy"
            component="li"
            onClick={() => openUrl(documents?.privacy)}
          >
            Política de privacidad
          </Box>
          <Box
            id="BF-contiditons"
            component="li"
            onClick={() => openUrl(documents?.terms)}
          >
            Condiciones
          </Box>
          <Box
            id="BF-cookies"
            component="li"
            onClick={() => openUrl(documents?.cookies)}
          >
            Política de cookies
          </Box>
          <Box
            id="BF-blog"
            component="li"
            onClick={() => openUrl("https://blog.bondup.cl")}
          >
            Blog
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const socialLinks = [
  ["facebook", "facebook"],
  ["x-twitter", "twitter"],
  ["instagram", "instagram"],
  ["linkedin", "linkedin"],
  ["tiktok", "tiktok"],
  ["whatsapp", "whatsapp"],
  ["youtube", "youtube"],
];

export default Footer;
